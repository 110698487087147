import { useEffect, useState, useContext } from 'react'

import { Form, TextField } from '@redwoodjs/forms'
import { navigate, routes, useParams } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import Button from 'src/components/Library/Button/Button'
import { DefaultAuthClient } from 'src/lib/authClients'
import { AuthClientContext } from 'src/lib/AuthContext'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'
import { useAuth } from 'src/Providers'

const PasswordResetRequestPage = () => {
  const [passwordError, setPasswordError] = useState<string>()
  const [resetLoading, setResetLoading] = useState(false)
  const { forgotPassword, isAuthenticated } = useAuth()
  const { resetEmail } = useParams()
  const { trackEvent } = useAnalytics()
  const [_auth, setAuth] = useContext(AuthClientContext)
  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.home())
    }
  }, [isAuthenticated])

  useEffect(() => {
    setAuth(DefaultAuthClient)
  }, [])
  const redirectPasswordResetToken = (email: string) => {
    const tID = setTimeout(function () {
      window.clearTimeout(tID) // clear time out.
      navigate(
        routes.passwordResetToken({ resetEmail: encodeURIComponent(email) }),
      )
    }, 5000)
  }

  const onSubmitResetRequest = async (data: { email: string }) => {
    setPasswordError(null)
    setResetLoading(true)

    try {
      const response = await forgotPassword(data.email)
      if (response instanceof Error) {
        throw response
      }
      setResetLoading(false)
      setPasswordError(
        'A verification code has been emailed to you. You will need to enter this on the next page. Redirecting now...',
      )
      redirectPasswordResetToken(data.email)
    } catch (error) {
      setPasswordError(error.message)
    }
  }

  // Added Intercom Chat
  const chatId = process.env.FRONT_THE_HUB_CHAT_ID
  const [openChat, setOpenChat] = useState(false)
  useEffect(() => {
    if (chatId && openChat && window.Intercom) {
      window.Intercom('show')

      window.Intercom('onHide', function () {
        setOpenChat(false)
      })
    }
  }, [openChat, chatId])

  return (
    <>
      <Metadata
        title="Request Password Reset"
        description="Request Password Reset"
      />
      <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="justify-center space-x-2">
            <TheHubIcon />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Request a password reset
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <Form className="space-y-6" onSubmit={onSubmitResetRequest}>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <TextField
                    id="email"
                    name="email"
                    autoComplete="email"
                    required
                    defaultValue={resetEmail}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#038EFD] focus:outline-none focus:ring-[#038EFD] sm:text-sm"
                  />
                </div>
              </div>
              {passwordError && (
                <p className="text-sm text-red-600">{passwordError}</p>
              )}

              <div>
                <Button
                  loading={resetLoading}
                  disabled={resetLoading}
                  type="submit"
                  onClick={() => {
                    trackEvent('Pages', 'click request reset password')
                  }}
                >
                  Request Reset
                </Button>
              </div>
            </Form>

            <div className="mt-4 flex items-center justify-end">
              <div className="text-sm">
                <Button
                  variant="text"
                  onClick={() => {
                    setOpenChat(true)
                    trackEvent('Pages', 'click having issues pw reset')
                  }}
                  fullWidth={false}
                >
                  Having issues?
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordResetRequestPage
